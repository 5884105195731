import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/layout';
import PhotoPage from '../components/PhotoPage';

const photo = ({ data }) => (
  <Layout>
    <SEO title="Photograph" />
    <PhotoPage items={data.photo} />
  </Layout>
);
export default photo;
export const query = graphql`
  {
    photo: allContentfulPhoto(sort: { fields: time, order: DESC }) {
      edges {
        node {
          id
          title
          time(formatString: "YYYY-MM-DD")
          country
          state
          img {
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
              src
            }
          }
        }
      }
    }
  }
`;
