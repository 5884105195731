import React, { Component } from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import './PhotoPage.css';

const getCategories = (items) => {
  let tempItems = items.map((items) => {
    return items.node.country;
  });
  let categories = Array.from(new Set(tempItems));
  categories = ['ALL', ...categories];
  return categories;
};

export default class PhotoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items.edges,
      originalItems: props.items.edges,
      categories: getCategories(props.items.edges),
    };
  }
  categoryHandle = (category) => {
    let tempItems = [...this.state.originalItems];

    if (category === 'ALL') {
      this.setState(() => {
        return { items: tempItems };
      });
    } else {
      let contryItems = tempItems.filter(
        ({ node }) => node.country === category
      );
      this.setState(() => {
        return { items: contryItems };
      });
    }
  };
  render() {
    let categoryView = null;
    categoryView = (
      <div className="category-view">
        {this.state.categories.map((category, index) => {
          return (
            <button
              className="category-button"
              key={index}
              type="button"
              onClick={() => {
                this.categoryHandle(category);
              }}
            >
              {category}
            </button>
          );
        })}
      </div>
    );
    if (this.state.items.length > 0) {
      return (
        <div className="page-items">
          {categoryView}
          <div className="photo-items">
            {this.state.items.map(({ node }) => {
              return (
                <div
                  className="item"
                  key={node.id}
                  onClick={() => {
                    window.open(node.img.fluid.src);
                  }}
                >
                  <Img
                    fluid={node.img.fluid}
                    className="photo-img"
                    alt={node.state}
                  />
                  <div className="item-location">
                    {node.state}, {node.country}
                  </div>
                  <div className="item-time">{node.time}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          {categoryView}
          <div className="raw">
            <div className="col">
              <h1>Still Working</h1>
            </div>
          </div>
        </div>
      );
    }
  }
}
